.divSLide hr {
  border: 1px solid #32425c;
  width: 30%;
  margin-bottom: 40px;
}
#insideMargin {
  margin-top: 2em;
}
.title {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  color: #32425c;
  text-align: left;
  font-size: 3em;
  line-height: 120%;
  margin: 0;
}
@media only screen and (max-width: 767px) {
  .title {
    font-size: 1.625em;
  }
}
.paragraphDiv {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #32425c;
  font-size: 1.25em;
  line-height: 145%;
  margin: 0;
}
@media only screen and (max-width: 767px) {
  .paragraphDiv {
    font-size: 0.875em;
  }
}
.paragraphDivPatenttitle {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #32425c;
  font-size: 1.25em;
  line-height: 145%;
  margin: 0;
}
@media only screen and (max-width: 768px) and (max-width: 1023px) {
  .paragraphDivPatenttitle {
    font-size: 1em;
  }
}
@media only screen and (max-width: 767px) {
  .paragraphDivPatenttitle {
    font-size: 0.875em;
  }
}
.paraTitle {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #32425c;
  text-align: left;
  font-size: 3em;
  line-height: 120%;
  margin: 0;
  width: 18em;
}
@media only screen and (max-width: 767px) {
  .paraTitle {
    font-size: 1.625em;
  }
}
@media only screen and (max-width: 1023px) {
  .paraTitle {
    width: 12em;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1365px) {
  .paraTitle {
    width: 14em;
  }
}
.paragraphDivServices {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #32425c;
  font-size: 1.375em;
  line-height: 145%;
}
@media only screen and (max-width: 767px) {
  .paragraphDivServices {
    font-size: 1.125em;
  }
}
.paragraphDivServicePara {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #32425c;
  font-size: 1.125em;
  line-height: 145%;
  margin: 0;
  width: 70%;
}
@media only screen and (max-width: 767px) {
  .paragraphDivServicePara {
    font-size: 0.875em;
    width: 90%;
    position: relative;
    left: 1em;
  }
}
.paragraphDivServicePoints {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #32425c;
  font-size: 1em;
  line-height: 145%;
  margin: 0;
  width: 70%;
}
@media only screen and (max-width: 767px) {
  .paragraphDivServicePoints {
    font-size: 0.875em;
    width: 90%;
  }
}
.paragraphDivPatent {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #32425c;
  font-size: 1.125em;
  line-height: 145%;
  margin: 0;
}
@media only screen and (max-width: 767px) {
  .paragraphDivPatent {
    font-size: 0.875em;
  }
}
@media only screen and (min-width: 768px) {
  .serviceexdiv {
    position: absolute;
    left: 3vw;
    bottom: 50px;
    width: 100%;
  }
}
#thirdComponent {
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
  margin-left: 5vw;
  margin-right: 5vw;
}
@media only screen and (max-width: 767px) {
  #thirdComponent {
    margin-left: 20px;
    margin-right: 20px;
  }
}
#fourthComponent {
  padding: 20px;
}
.blogT2 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #32425c;
  text-align: left;
  font-size: 1em;
  line-height: 145%;
  margin: 0;
}
@media only screen and (max-width: 767px) {
  .blogT2 {
    font-size: 0.875em;
  }
}
.divBlog {
  padding: 20px;
}
.slick-dots li button:before {
  color: #32425c;
}
.slick-dots li.slick-active button:before {
  color: #32425c;
}
@media only screen and (max-width: 567px) {
  .desktopViewBlog {
    display: none;
  }
}
@media only screen and (min-width: 568px) {
  .desktopViewBlog {
    display: block;
  }
}
@media only screen and (min-width: 568px) {
  .mobViewBlog {
    display: none;
  }
}
@media only screen and (max-width: 567px) {
  .mobViewBlog {
    display: block;
  }
}
@media only screen and (max-width: 767px) {
  .mobService {
    display: block;
  }
}
@media only screen and (min-width: 768px) {
  .mobService {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  #deskService {
    display: none;
  }
}
@media only screen and (min-width: 768px) {
  #deskService {
    display: block;
  }
}
.arrow-btn {
  background: none;
  border: none;
  cursor: pointer;
  transition: 0.5s;
}
.arrow-btn img {
  position: relative;
  bottom: 10px;
}
.prev:hover {
  transform: translateX(-0.1875em);
}
.next:hover {
  transform: translateX(0.1875em);
}
.next {
  float: right;
}
.buttonLoad {
  margin: 0 auto;
  text-align: center;
  margin-bottom: 20px;
  background: none;
  border: none;
  color: #32425c;
  font-size: 0.875em;
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  font-style: normal;
}
.buttonLoad:hover {
  text-decoration: underline;
}
.buttonLoadHome {
  background-color: #2a3749;
  border: none;
  color: #fff;
  font-size: 1em;
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  font-style: normal;
  cursor: pointer;
  height: 57px;
  padding-left: 20px;
  padding-right: 20px;
  transition: 0.5s;
  outline: none;
}
@media only screen and (max-width: 767px) {
  .buttonLoadHome {
    font-size: 0.875em;
  }
}
.buttonLoadHome:hover {
  background-color: #435573;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  outline: none;
}
.buttonLoadHome:active {
  background-color: #141d2b;
  transform: translateY(5px);
  outline: none;
}
.contactForm {
  margin-top: 30px;
}
@media only screen and (min-width: 568px) {
  .contactForm {
    width: 500px;
  }
  .contactForm button {
    width: 522px;
  }
}
@media only screen and (max-width: 567px) {
  .contactForm {
    width: 280px;
  }
  .contactForm button {
    width: 300px;
  }
}
.contactForm input {
  width: 100%;
  margin-bottom: 10px;
  height: 40px;
}
.contactForm textarea {
  width: 100%;
  margin-bottom: 10px;
  height: 140px;
}
.contactForm ::placeholder {
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 0.875em;
  color: #36455a;
}
.contactForm input[type="text"],
.contactForm input[type="email"] {
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 0.875em;
  color: #36455a;
  border: 2px solid #36455a;
  overflow: hidden;
  padding-left: 15px;
}
.contactForm textarea {
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 0.875em;
  color: #36455a;
  border: 2px solid #36455a;
  overflow: hidden;
  padding-left: 15px;
  padding-top: 10px;
}
.contactForm p {
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 0.875em;
  color: red;
  padding-bottom: 20px;
  margin: 0;
}
.contactForm label {
  padding-bottom: 5px;
}
.containerDiv {
  height: 60px;
  position: relative;
}
.center {
  margin: 0;
  position: absolute;
  top: 100%;
  left: 50%;
  -ms-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%);
}
.parallax-effect-glare-scale {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  transform-style: preserve-3d;
  cursor: pointer;
}
.inner-element {
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 1em;
  color: white;
  transform: translateZ(60px);
  position: absolute;
  bottom: 20px;
  display: none;
  width: 72%;
}
@media only screen and (max-width: 1365px) {
  .inner-element {
    font-size: 0.875em;
  }
}
@media screen and (min-width: 568px) {
  .inner-element {
    left: 10%;
  }
}
@media screen and (max-width: 567px) {
  .inner-element {
    width: 93%;
    display: block;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #0c1726 100%);
  }
  .inner-element p {
    position: relative;
    left: 5%;
    width: 95%;
  }
}
.parallax-effect-glare-scale:after {
  content: "\A";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #0c1726 100%);
  mix-blend-mode: normal;
  opacity: 0;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}
.parallax-effect-glare-scale:hover::after {
  opacity: 1;
}
.parallax-effect-glare-scale:hover .inner-element {
  display: block;
}
@media screen and (min-width: 568px) {
  #newDesk {
    margin-top: 50px;
  }
}
.newDiv .wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
  row-gap: 20px;
  margin-bottom: 20px;
}
@media only screen and (min-width: 568px) and (max-width: 1023px) {
  .newDiv .wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 567px) {
  .newDiv .wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}
.newDiv .cssanimation {
  opacity: 0;
  animation-duration: 1s;
  animation-fill-mode: both;
}
@media only screen and (max-width: 567px) {
  .newDiv .cssanimation {
    animation: none;
    opacity: 1;
  }
}
.newDiv #notVisible {
  opacity: 0;
}
@media only screen and (max-width: 567px) {
  .newDiv #notVisible {
    opacity: 1;
  }
}
.newDiv #visible {
  opacity: 1;
}
.newDiv #fadeInBottom {
  animation-name: fadeInBottom;
}
@keyframes fadeInBottom {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
  }
}
.newDiv .wrapperServices {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 30px;
  row-gap: 30px;
  margin-top: 2em;
}
@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  .newDiv .wrapperServices {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (min-width: 568px) and (max-width: 1023px) {
  .newDiv .wrapperServices {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 567px) {
  .newDiv .wrapperServices {
    grid-template-columns: repeat(1, 1fr);
  }
}
.newDiv .capabilitiesGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 130px;
  row-gap: 60px;
}
@media only screen and (min-width: 568px) and (max-width: 1023px) {
  .newDiv .capabilitiesGrid {
    grid-template-columns: repeat(4, 1fr);
    column-gap: 80px;
  }
}
@media only screen and (max-width: 567px) {
  .newDiv .capabilitiesGrid {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
  }
}
@media only screen and (max-width: 567px) {
  .newDiv #insideMarginRow {
    margin-top: 60px;
  }
}
@media only screen and (min-width: 568px) {
  .newDiv #insideMarginRow {
    margin-top: 80px;
  }
}
.newDiv .capabilitiesGrid-item {
  text-align: center;
  position: relative;
  height: 150px;
}
.newDiv .lottieDiv {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, 0);
}
.newDiv .wrapperServicesHomeDesktop {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 130px;
}
@media only screen and (min-width: 1024px) and (max-width: 1365px) {
  .newDiv .wrapperServicesHomeDesktop {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media only screen and (max-width: 1023px) {
  .newDiv .wrapperServicesHomeDesktop {
    display: none;
  }
}
.newDiv .wrapperServicesHomeIpad {
  display: grid;
  column-gap: 100px;
}
@media only screen and (min-width: 1024px) {
  .newDiv .wrapperServicesHomeIpad {
    display: none;
  }
}
@media only screen and (min-width: 568px) and (max-width: 1023px) {
  .newDiv .wrapperServicesHomeIpad {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 567px) {
  .newDiv .wrapperServicesHomeIpad {
    display: none;
  }
}
.newDiv .wrapperServicesHomeMob {
  display: grid;
  column-gap: 20px;
  row-gap: 60px;
}
@media only screen and (min-width: 568px) {
  .newDiv .wrapperServicesHomeMob {
    display: none;
  }
}
@media only screen and (max-width: 567px) {
  .newDiv .wrapperServicesHomeMob {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (min-width: 1024px) {
  .newDiv .secondTop {
    margin-top: 130px;
  }
}
@media only screen and (min-width: 568px) and (max-width: 1023px) {
  .newDiv .secondTop {
    margin-top: 80px;
  }
}
.newDiv .greetServiceHome {
  text-align: center;
}
.newDiv .greetServiceHomeMob {
  text-align: center;
}
.newDiv .wrapperAbout {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 20px;
  row-gap: 50px;
}
@media only screen and (min-width: 568px) and (max-width: 1023px) {
  .newDiv .wrapperAbout {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 567px) {
  .newDiv .wrapperAbout {
    grid-template-columns: repeat(2, 1fr);
  }
}
.newDiv .wrapper-itemA {
  cursor: pointer;
  position: relative;
  padding: 50%;
  background: #f9f9f9;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}
.newDiv .servicesLogo {
  margin-bottom: 30px;
}
.newDiv .wrapper-itemS {
  cursor: pointer;
  position: relative;
  padding: 50%;
  background: #f9f9f9;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  transition: all 0.5s ease 0s;
}
@media only screen and (max-width: 567px) {
  .newDiv .wrapper-itemS {
    padding: 35%;
  }
}
.newDiv .wrapper-itemSHome {
  cursor: pointer;
  position: relative;
  height: 10em;
}
.newDiv .wrapper-itemS:hover {
  box-shadow: 0px 20px 10px rgba(0, 0, 0, 0.1);
}
.newDiv .centerService {
  transition: all 0.5s ease 0s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
}
.newDiv .wrapper-itemS:hover .centerService {
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, -100%);
  opacity: 0;
}
.newDiv .newButtons2 .close {
  width: 16px;
  position: relative;
  top: 33px;
  right: 45px;
}
@media screen and (max-width: 567px) {
  .newDiv .newButtons2 .close {
    top: 32px;
  }
}
.newDiv .newButtons2 .buttonClick {
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 1em;
  line-height: 145%;
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  overflow: hidden;
  float: left;
  margin: 10px;
  height: 64px;
  color: #2a3749;
  position: relative;
  border: none;
  padding: 20px;
  cursor: pointer;
  transition: 0.5s;
}
@media only screen and (max-width: 767px) {
  .newDiv .newButtons2 .buttonClick {
    font-size: 0.75em;
    padding: 15px;
  }
}
.newDiv .newButtons2 .buttonClick:hover {
  background: #fafafa;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}
.newDiv .newButtons2 .buttonClick:active {
  background: #efefef;
  transform: translateY(5px);
  outline: none;
}
.newDiv .newButtons2 .buttonClick1 {
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  font-style: normal;
  box-shadow: 0px 4px 8px 1px rgba(36, 52, 72, 0.24);
  overflow: hidden;
  float: left;
  margin: 10px;
  height: 64px;
  position: relative;
  background: #2a3749;
  color: #fff;
  border: none;
  padding: 20px 45px 30px 20px;
  cursor: pointer;
  font-size: 1em;
  line-height: 145%;
  transition: 0.5s;
}
@media only screen and (min-width: 568px) and (max-width: 767px) {
  .newDiv .newButtons2 .buttonClick1 {
    font-size: 0.75em;
  }
}
@media screen and (max-width: 567px) {
  .newDiv .newButtons2 .buttonClick1 {
    font-size: 0.75em;
    padding-left: 15px;
    padding-top: 15px;
    padding-right: 45px;
    padding-bottom: 15px;
  }
}
.newDiv .newButtons2 .buttonClick1:hover {
  background-color: #435573;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}
.newDiv .newButtons2 .buttonClick1:active {
  background-color: #141d2b;
  transform: translateY(5px);
  outline: none;
}
.newDiv .buttonClick:disabled {
  cursor: default;
}
.newDiv .MuiFormControlLabel-root {
  margin-right: 0px;
  margin-left: 0px;
}
.image3 {
  object-fit: cover;
}
.image4 {
  object-fit: contain;
}
@media only screen and (min-width: 568px) {
  .next-week {
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
  }
  .next-week .next-icon {
    width: auto;
    float: right;
    position: relative;
    margin-left: auto;
  }
}
#projectselectedfirstPara {
  width: 50em;
  margin: 0;
}
@media only screen and (max-width: 567px) {
  #projectselectedfirstPara {
    width: 100%;
  }
}
@media only screen and (min-width: 568px) and (max-width: 767px) {
  #projectselectedfirstPara {
    width: 18em;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  #projectselectedfirstPara {
    width: 25em;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1365px) {
  #projectselectedfirstPara {
    width: 35em;
  }
}
.bhairavDP {
  width: 25vw;
  height: 25vw;
}
@media only screen and (max-width: 567px) {
  .bhairavDP {
    width: 100%;
    height: 100%;
  }
}
#intro {
  height: 100%;
  /* Or 100vh */
  flex-direction: column;
  display: flex;
  align-items: center;
  /* Align the inner div vertically */
  justify-content: center;
  /* Align the inner div horizontally */
}
.desktopViewDiv {
  display: block;
}
@media only screen and (max-width: 1023px) {
  .desktopViewDiv {
    display: none;
  }
}
.mobViewDiv {
  display: block;
}
@media only screen and (min-width: 1024px) {
  .mobViewDiv {
    display: none;
  }
}
.blogDiv {
  margin-right: 30px;
  background-color: #fff;
  box-shadow: 0px 10px 10px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
  transition: all 0.5s ease;
  cursor: pointer;
}
.blogDiv:hover {
  box-shadow: 0px 20px 30px 0 rgba(0, 0, 0, 0.1);
  transform: translateY(-7px);
}
#projectDiv {
  margin-bottom: 20px;
  transition: all 0.5s ease;
  cursor: pointer;
}
#projectDiv:hover {
  transform: translateY(-7px);
}
.blogDivMob {
  background-color: #fff;
  box-shadow: 0px 10px 10px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  transition: all 0.5s ease;
  cursor: pointer;
}
@keyframes slideIn {
  0% {
    transform: translate3d(0, -50%, 0);
    opacity: 0;
  }
  6.66% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  26.66% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  33.3% {
    transform: translate3d(0, 50%, 0);
    opacity: 0;
  }
  33.33% {
    transform: translate3d(0, 50%, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, -50%, 0);
    opacity: 0;
  }
}
.container_row {
  display: grid;
}
.layer1,
.layer2,
.layer3 {
  grid-column: 1;
  grid-row: 1;
  opacity: 0;
}
.layer1 {
  animation: slideIn 12s forwards cubic-bezier(0.1, 0.67, 0.29, 0.98) infinite;
  -webkit-animation: slideIn 12s forwards cubic-bezier(0.1, 0.67, 0.29, 0.98)
    infinite;
}
.layer2 {
  animation: slideIn 12s 4s forwards cubic-bezier(0.1, 0.67, 0.29, 0.98)
    infinite;
  -webkit-animation: slideIn 12s 4s forwards cubic-bezier(0.1, 0.67, 0.29, 0.98)
    infinite;
}
.layer3 {
  animation: slideIn 12s 8s forwards cubic-bezier(0.1, 0.67, 0.29, 0.98)
    infinite;
  -webkit-animation: slideIn 12s 8s forwards cubic-bezier(0.1, 0.67, 0.29, 0.98)
    infinite;
}
.item {
  vertical-align: top;
  display: inline-block;
  text-align: center;
}
.itemService {
  vertical-align: top;
  display: inline-block;
  text-align: center;
  background: #f9f9f9;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  height: 300px;
  position: relative;
}
.subItems {
  transition: all 0.5s ease 0s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
}
.subItemsBottom {
  transition: all 0.5s ease 0s;
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, 0);
  opacity: 0;
  width: 100%;
}
#hoverID:hover .subItemsBottom {
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  opacity: 1;
}
#hoverID:hover .subItems {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -75%);
  opacity: 0;
}
.caption {
  display: block;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #32425c;
  font-size: 1em;
  text-align: center;
  line-height: 120%;
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 100%;
  transform: translate(-50%, 0);
}
@media only screen and (max-width: 1023px) {
  .caption {
    font-size: 0.875em;
  }
}
.captionS {
  display: block;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #32425c;
  font-size: 1em;
  text-align: center;
}
@media only screen and (max-width: 1023px) {
  .captionS {
    font-size: 0.875em;
  }
}
.iconDetails {
  float: left;
}
.container2 {
  float: left;
}
.left {
  float: left;
}
.right {
  float: left;
}
.upA {
  transform: rotate(180deg);
}
.divPatent {
  height: 100px;
  overflow: visible;
  transition: height 1.25s ease-in !important;
}
#box {
  -moz-transition: height 0.5s;
  -ms-transition: height 0.5s;
  -o-transition: height 0.5s;
  -webkit-transition: height 0.5s;
  transition: height 0.5s;
  height: 0;
  overflow: hidden;
  outline: 1px solid red;
}
@media only screen and (min-width: 568px) {
  #patentIddiv {
    float: right;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
@media only screen and (max-width: 567px) {
  #patentIddiv {
    padding-top: 10px;
  }
}
@media only screen and (min-width: 568px) {
  #patentId2 {
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
  }
}
@media only screen and (max-width: 567px) {
  #patentId2 {
    padding-top: 5px;
  }
}
.divPatent1 {
  height: 0px;
}
.divPatent1-active {
  height: 100px;
  transition: height 0.3s ease;
  -webkit-transition: height 0.3s ease;
}
@media only screen and (max-width: 567px) {
  .paddingMobPatent {
    padding-top: 15px;
  }
}
@media only screen and (min-width: 568px) {
  .deskColon {
    display: block;
  }
}
@media only screen and (max-width: 567px) {
  .deskColon {
    display: none;
  }
}
@media only screen and (min-width: 568px) {
  .mobColon {
    display: none;
  }
}
@media only screen and (max-width: 567px) {
  .mobColon {
    display: block;
  }
}
.gap {
  height: 100vh;
}
.animated {
  flex-basis: 600px;
  flex-shrink: 1;
}
.row__2 {
  display: flex;
  justify-content: center;
}
.box {
  height: 100%;
  background: #e9e9e9;
  border-radius: 3px;
  box-shadow: 2px 1px 2px lightgray;
}
.box2 {
  background: #e9e9e9;
}
.opacityDiv {
  opacity: 1;
}
.opacityDivNone {
  opacity: 0;
}
#sortingIDdiv .sortingText {
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 1.125em;
  background: none;
  border: none;
  color: #2a3749;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  top: 2px;
  left: 7px;
}
#sortingIDdiv .spaceBetweenCheck {
  margin-right: 68px;
  margin-bottom: 1em;
}
#sortingIDdiv input[type="checkbox"] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  color: #2a3749;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid #2a3749;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
}
#sortingIDdiv input[type="checkbox"]:hover {
  background-color: #d7d7d7;
}
#sortingIDdiv input[type="checkbox"]:checked {
  background-color: #2a3749;
}
#firstcomp .wrapper {
  position: relative;
  overflow: hidden;
}
#firstcomp img {
  width: 70%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 0;
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
@media only screen and (max-width: 567px) {
  #firstcomp img {
    width: 100%;
  }
}
#firstcomp .lazyAspect {
  padding-bottom: 55%;
}
@media only screen and (max-width: 567px) {
  #firstcomp .lazyAspect {
    padding-bottom: 80%;
  }
}
#firstcomp .source {
  opacity: 0;
  transition: opacity 1200ms;
}
#firstcomp .loaded {
  opacity: 1;
}
.capabilitiesGridHomepage {
  text-align: center;
  position: relative;
  height: 100px;
  cursor: pointer;
}
.lottieDivHome {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, 0);
}
@media only screen and (max-width: 567px) {
  #insideMarginRow1 {
    margin-top: 60px;
  }
}
@media only screen and (min-width: 568px) {
  #insideMarginRow1 {
    margin-top: 80px;
  }
}
